<template>
    <layout-full-width :title="$tc('linked-service', 2)">

        <div class="row">
            <div class="col-12 d-flex justify-content-center">
                <table class="table border bg-white mt-3" style="max-width: 1080px;">
                    <thead>
                    <tr>
                        <th scope="col" style="width: 240px;">{{ $tc('add-on', 1) }}</th>
                        <th scope="col">{{ $tc('description', 1) }}</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td class="d-flex align-middle align-items-center">
                            <img :src="require('@/assets/img/cliffmarkets-logo.png')"
                                 alt="CLIFF Markets"
                                 class="me-2"
                                 height="30px"
                                 width="30px">
                            <div>
                                <strong>CLIFF Markets</strong><br>
                                <small>STINA Business Solutions GmbH</small>
                            </div>
                        </td>
                        <td class="align-middle">
                            {{ $t('linked-services.cliff-markets-description') }}
                        </td>
                        <td class="align-middle">
                            <div class="dropdown">
                                <button id="dropdownMenuButton"
                                        aria-expanded="false"
                                        aria-haspopup="true"
                                        class="btn bg-transparent"
                                        data-bs-toggle="dropdown"
                                        type="button">
                                    <font-awesome-icon icon="ellipsis-h"/>
                                </button>
                                <div aria-labelledby="dropdownMenuButton" class="dropdown-menu dropdown-menu-right">
                                    <a class="dropdown-item"
                                       href="https://www.cliffmarkets.com/sales"
                                       target="_blank">
                                        {{ $t('actions.contact-sales') }}
                                    </a>
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td class="d-flex align-middle align-items-center">
                            <img :src="require('@/assets/img/logo_stina_sm.png')"
                                 alt="dataPONS"
                                 class="me-2"
                                 height="30px"
                                 width="30px">
                            <div>
                                <strong>dataPONS</strong><br>
                                <small>STINA Business Solutions GmbH</small>
                            </div>
                        </td>
                        <td class="align-middle">
                            {{ $t('linked-services.data-pons-description') }}
                        </td>
                        <td class="align-middle">
                            <div class="dropdown">
                                <button id="dropdownMenuButton"
                                        aria-expanded="false"
                                        aria-haspopup="true"
                                        class="btn bg-transparent"
                                        data-bs-toggle="dropdown"
                                        type="button">
                                    <font-awesome-icon icon="ellipsis-h"/>
                                </button>
                                <div aria-labelledby="dropdownMenuButton" class="dropdown-menu dropdown-menu-right">
                                    <a class="dropdown-item"
                                       href="https://www.stina-global.com/sales"
                                       target="_blank">
                                        {{ $t('actions.contact-sales') }}
                                    </a>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="d-flex align-middle align-items-center">
                            <img :src="require('@/assets/img/forklane-logo.png')"
                                 alt="FORK LANE"
                                 class="me-2"
                                 height="30px"
                                 width="30px">
                            <div>
                                <strong>FORK LANE</strong><br>
                                <small>FORK LANE Ltd</small>
                            </div>
                        </td>
                        <td class="align-middle">
                            {{ $t('linked-services.fork-lane-description') }}
                        </td>
                        <td class="align-middle">
                            <div class="dropdown">
                                <button id="dropdownMenuButton"
                                        aria-expanded="false"
                                        aria-haspopup="true"
                                        class="btn bg-transparent"
                                        data-bs-toggle="dropdown"
                                        type="button">
                                    <font-awesome-icon icon="ellipsis-h"/>
                                </button>
                                <div aria-labelledby="dropdownMenuButton" class="dropdown-menu dropdown-menu-right">
                                    <a class="dropdown-item" href="https://www.forklane.com/sales" target="_blank">
                                        {{ $t('actions.contact-sales') }}
                                    </a>
                                </div>
                            </div>
                        </td>
                    </tr>


                    <tr>
                        <td class="d-flex align-middle align-items-center">
                            <img :src="require('@/assets/img/interactive-gem-logo.png')"
                                 alt="Interactive GEM"
                                 class="me-2"
                                 height="30px"
                                 width="30px">
                            <div>
                                <strong>Interactive GEM</strong><br>
                                <small>Interactive GEM Ltd</small>
                            </div>
                        </td>
                        <td class="align-middle">
                            {{ $t('linked-services.interactive-gem-description') }}
                        </td>
                        <td class="align-middle">
                            <div class="dropdown">
                                <button id="dropdownMenuButton"
                                        aria-expanded="false"
                                        aria-haspopup="true"
                                        class="btn bg-transparent"
                                        data-bs-toggle="dropdown"
                                        type="button">
                                    <font-awesome-icon icon="ellipsis-h"/>
                                </button>
                                <div aria-labelledby="dropdownMenuButton" class="dropdown-menu dropdown-menu-right">
                                    <a class="dropdown-item"
                                       href="https://www.interactivegem.com/sales"
                                       target="_blank">
                                        {{ $t('actions.contact-sales') }}
                                    </a>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";

export default {
    name: "PageLinkedServices",
    components: {LayoutFullWidth}
}
</script>

<style lang="scss" scoped></style>